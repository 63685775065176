import Input from 'components/common/input';
import {careManagerInitialValues, careManagerSchema} from 'constants/careManager';
import {ErrorMessage, Formik} from 'formik';
import SelectComponent from 'components/common/select';
import ToggleButton from 'components/common/togglebutton';
import React from 'react';
import AsyncSelect from 'components/common/select/AsyncSelect';
import {getCounties, getStates, getHealthPlans} from 'containers/policies/api';
const makeLabel = item => {
  const {county, state, city, code} = item;
  let zipCode = code;
  let cityName = city?.name | 'abc' | city | 'city';
  let countyName = county?.name | county | 'county';
  return `${zipCode} ${cityName} ${countyName}`;
};
const CareManagerForm = ({
  handleQuestionSubmittion,
  handleClose,
  currentLeadCareManagerEdit,
  zipCodeList,
  handleZipcodeSearch,
  healthPlansList,
  countiesList,
  stateList,
}) => {
  return (
    <Formik
      initialValues={
        typeof currentLeadCareManagerEdit === 'object' ? currentLeadCareManagerEdit : careManagerInitialValues
      }
      validationSchema={careManagerSchema}
      onSubmit={values => {
        handleQuestionSubmittion(values);
      }}
    >
      {({handleChange, values, handleSubmit, errors, setFieldValue}) => {
        console.log('errors', errors);
        const customHandleChange = event => {
          const {name, value} = event.target;
          // if (name === 'capacity') {
          //   const parsedValue = parseInt(value, 10);
          //   handleChange({
          //     target: {
          //       name,
          //       value: isNaN(parsedValue) ? '' : parsedValue,
          //     },
          //   });
          // } else {
          //   handleChange(event);
          // }
        };

        return (
          <div className="question-modal">
            <form className="form-main" onSubmit={handleSubmit}>
              <hr className="custom-modal-line mt-0 " />
              {/*
              <div className="input-flex row">
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. John "
                    type="text"
                    // label="LCM Supervisor's First Name"
                    label="First Name"
                    value={values.first_name}
                    name="first_name"
                    error={<ErrorMessage className="error-message" component="p" name="first_name" />}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. Doe"
                    type="text"
                    label="Last Name"
                    value={values.last_name}
                    name="last_name"
                    error={<ErrorMessage className="error-message" component="p" name="last_name" />}
                  />
                </div>
              </div> */}
              <div className="input-flex row">
                <div className="col-sm-6">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. Michael Johnson"
                    type="text"
                    label="Care Coordinator Name"
                    value={values.care_coordinator_name}
                    name="care_coordinator_name"
                    error={<ErrorMessage className="error-message" component="p" name="care_coordinator_name" />}
                  />
                </div>
                <div className="col-sm-12">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. johndoe@gmail.com"
                    type="email"
                    label="Email"
                    value={values.email}
                    disabled={
                      typeof currentLeadCareManagerEdit === 'object' && Object.keys(currentLeadCareManagerEdit).length
                    }
                    name="email"
                    error={<ErrorMessage className="error-message" component="p" name="email" />}
                  />
                </div>
              </div>

              <div className="input-flex row">
                <div className="col-sm-12 ">
                  <Input
                    handleChange={handleChange}
                    placeholder="e.g. VN Consulting, Northern California"
                    type="text"
                    label="Location(s)"
                    value={values.location}
                    name="location"
                    error={<ErrorMessage className="error-message" component="p" name="location" />}
                  />
                </div>
                {/* <div className="col-sm-6 ">
                  <Input
                    handleChange={handleChange}
                    placeholder="Enter Capacity"
                    type="number"
                    label="Capacity"
                    value={values.capacity}
                    name="capacity"
                    error={<ErrorMessage className="error-message" component="p" name="capacity" />}
                  />
                </div> */}
                <div className="col-sm-6 mt-2 pt-1">
                  <ToggleButton
                    label="Status"
                    handleChange={(name, value) => {
                      setFieldValue(name, !!value ? 'active' : 'inactive');
                    }}
                    value={values.status === 'active' ? 1 : 0}
                    name="status"
                    // disabled={currentQuestionEdit ? true : false}
                  />
                </div>
                <div>
                  <AsyncSelect
                    label="Health Plan"
                    isMulti={true}
                    options={healthPlansList?.map(el => ({value: el.id, label: el.name}))}
                    dataCallback={async inputValue => {
                      const response = await getHealthPlans({search: inputValue});
                      const formatedOptions = response?.health_plans?.map(el => ({
                        value: el.id,
                        label: el.name,
                      }));
                      return formatedOptions;
                    }}
                    selectedValue={values.health_plans}
                    placeholder="Select Health Plan "
                    handleChange={arr => {
                      setFieldValue('health_plans', arr);
                    }}
                    error={<ErrorMessage className="error-message" component="p" name="health_plan_id" />}
                  />
                </div>
                <div>
                  <AsyncSelect
                    label="State"
                    isMulti={true}
                    options={stateList?.map(el => {
                      return {value: el.id, label: el.name};
                    })}
                    dataCallback={async inputValue => {
                      const response = await getStates({search: inputValue});
                      const formatedOptions = response?.states?.map(el => ({
                        value: el.id,
                        label: el.name,
                      }));
                      return formatedOptions;
                    }}
                    selectedValue={values.states}
                    placeholder="Select State "
                    handleChange={arr => {
                      setFieldValue('states', arr);
                    }}
                    error={<ErrorMessage className="error-message" component="p" name="state_id" />}
                  />
                </div>
                <div>
                  <AsyncSelect
                    label="County"
                    isMulti={true}
                    options={countiesList?.map(el => {
                      return {value: el.id, label: el.name};
                    })}
                    dataCallback={async inputValue => {
                      const response = await getCounties({search: inputValue});
                      const formatedOptions = response?.counties?.map(el => ({
                        value: el.id,
                        label: el.name,
                      }));
                      return formatedOptions;
                    }}
                    selectedValue={values.counties}
                    placeholder="Select County "
                    handleChange={arr => {
                      setFieldValue('counties', arr);
                    }}
                    error={<ErrorMessage className="error-message" component="p" name="county_id" />}
                  />
                </div>
                {/* <div className="col-sm-12">
                  <SelectComponent
                    label="Zip Code"
                    isMulti={true}
                    // options={zipCodeList.map(el => ({...el,value: el.code, label: el.code}))}
                    options={zipCodeList.map(el => ({
                      value: el.code,
                      label: `${el.code} ${el.city?.name || el.city || ''} ${el.county?.name || el.county || ''}`,
                    }))}
                    selectedValue={values.zip_codes}
                    placeholder="Select Zip Code "
                    handleChange={arr => {
                      setFieldValue('zip_codes', arr);
                    }}
                    handleChangeSearch={searchString => {
                      handleZipcodeSearch(searchString);
                    }}
                    error={<ErrorMessage className="error-message" component="p" name="zip_codes" />}
                  />
                </div> */}
              </div>

              {/* <div>
                <div className="input-wrapper">
                  <Input
                    handleChange={customHandleChange}
                    placeholder="Select Capacity Limit"
                    type="text"
                    label="Capacity Limit"
                    value={values.capacity !== '' ? `${values.capacity}` : ''}
                    name="capacity"
                    error={<ErrorMessage className="error-message" component="p" name="capacity" />}
                  />
                  <CaretDown className="caret-down-icon opacity-50" />
                </div>
              </div> */}
              <hr className="custom-modal-line mt-4 " />

              <div className="action gap-2">
                <button className="secondary-btn w-50" type="button" onClick={handleClose}>
                  Cancel
                </button>
                <input
                  className="primary-btn w-50 fs-6   small"
                  type="submit"
                  name="submit"
                  value={
                    typeof currentLeadCareManagerEdit === 'object' && Object.keys(currentLeadCareManagerEdit).length
                      ? 'Save Changes'
                      : 'Add LCM'
                  }
                />
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default CareManagerForm;
