import MenuHideIcon from 'assets/images/contract-left-line.png';
import ExpandMenuIcon from 'assets/images/expand-menu.png';
import HomeIcon from 'assets/images/home.svg';
import LeadcareIcon from 'assets/images/lead-care-icon.svg';
import {ReactComponent as MiniLogo} from 'assets/images/logo.svg';
import OutreachStafIcon from 'assets/images/outreach-staf-icon.svg';
import OutreactLeaderIcon from 'assets/images/outreact-leader-icon.svg';
import QuestionIcon from 'assets/images/question.svg';
import ReleaseIcon from 'assets/images/release.svg';
import MenuShowIcon from 'assets/images/sidebarshow-icon.svg';
import UserIcon from 'assets/images/user.svg';
import classNames from 'classnames';
import LatestRelease from 'components/release/LatestRelease';
import {LOGIN_ROLES} from 'constants/common';
import {
  DASHBOARD_PATH,
  Leadcaremanager_PATH,
  Members_PATH,
  OutreachLeaders_PATH,
  OutreachStaff_PATH,
  POLICIES_PATH,
  QUESTIONS_PATH,
  RELEASE_PATH,
  SURVEY_HISTORIES_PATH,
} from 'constants/routePaths';
import {Notepad, UserList} from 'phosphor-react';
import React, {useState} from 'react';
import {Accordion, Offcanvas} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {getUserInfo} from 'utils/common';
import './sidebar.scss';

const Sidebar = () => {
  const [showLabels, setShowLabel] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggleSidebarStyle = () => setShowLabel(pre => !pre);

  const routes =
    getUserInfo()?.type === LOGIN_ROLES.outreachleader && getUserInfo()?.super_admin
      ? [
          {
            path: Members_PATH,
            icon: <img src={UserIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Members',
          },

          {
            path: DASHBOARD_PATH,
            icon: <img src={HomeIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Dashboard',
          },
          {
            path: QUESTIONS_PATH,
            icon: <img src={QuestionIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Questions',
          },
          {
            path: Leadcaremanager_PATH,
            icon: <img src={LeadcareIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'LCM Supervisors',
          },
          {
            path: OutreachLeaders_PATH,
            icon: <img src={OutreactLeaderIcon} className="my-img-svg " width={26} alt="icon" fill="red" />,
            label: 'Outreach Leaders',
          },
          {
            path: OutreachStaff_PATH,
            icon: <img src={OutreachStafIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Outreach Staff',
          },
          {
            path: POLICIES_PATH,
            icon: <Notepad size={32} />,
            label: 'Policies',
          },
          {
            path: SURVEY_HISTORIES_PATH,
            icon: <UserList size={24} />,
            label: 'History',
          },
          {
            path: RELEASE_PATH,
            icon: <img src={ReleaseIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Releases',
          },
        ]
      : getUserInfo()?.type === LOGIN_ROLES.outreachleader
      ? [
          {
            path: Members_PATH,
            icon: <img src={UserIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Members',
          },

          {
            path: DASHBOARD_PATH,
            icon: <img src={HomeIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Dashboard',
          },
          {
            path: Leadcaremanager_PATH,
            icon: <img src={LeadcareIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'LCM Supervisors',
          },
          {
            path: OutreachLeaders_PATH,
            icon: <img src={OutreactLeaderIcon} className="my-img-svg " width={26} alt="icon" fill="red" />,
            label: 'Outreach Leaders',
          },
          {
            path: OutreachStaff_PATH,
            icon: <img src={OutreachStafIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Outreach Staff',
          },
          {
            path: POLICIES_PATH,
            icon: <Notepad size={32} />,
            label: 'Policies',
          },
          {
            path: SURVEY_HISTORIES_PATH,
            icon: <UserList size={24} />,
            label: 'History',
          },
        ]
      : getUserInfo()?.type === LOGIN_ROLES.outreachleader
      ? [
          {
            path: Members_PATH,
            icon: <img src={UserIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Members',
          },

          {
            path: DASHBOARD_PATH,
            icon: <img src={HomeIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Dashboard',
          },
          {
            path: Leadcaremanager_PATH,
            icon: <img src={LeadcareIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'LCM Supervisors',
          },
          {
            path: OutreachLeaders_PATH,
            icon: <img src={OutreactLeaderIcon} className="my-img-svg " width={26} alt="icon" fill="red" />,
            label: 'Outreach Leaders',
          },
          {
            path: OutreachStaff_PATH,
            icon: <img src={OutreachStafIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Outreach Staff',
          },
          {
            path: POLICIES_PATH,
            icon: <Notepad size={32} />,
            label: 'Policies',
          },
          {
            path: SURVEY_HISTORIES_PATH,
            icon: <UserList size={24} />,
            label: 'History',
          },
        ]
      : getUserInfo()?.type === LOGIN_ROLES.outreachstaff
      ? [
          {
            path: Members_PATH,
            icon: <img src={UserIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Members',
          },

          {
            path: DASHBOARD_PATH,
            icon: <img src={HomeIcon} className="my-img-svg " width={26} alt="icon" />,
            label: 'Dashboard',
          },
        ]
      : [];
  const renderLinks = showLabels => {
    return routes.map((route, index) => (
      <NavLink to={route.path} key={index} onClick={handleClose}>
        <p className="menu-linkk">
          {React.cloneElement(route.icon)}
          {showLabels && <span className="heading-smb">{route.label}</span>}
        </p>
      </NavLink>
    ));
  };
  const navMain = (
    <>
      <div className="logo-menu-otr">
        <div className="logo-otr">
          <MiniLogo />
        </div>
        <div className="menu-icon-otr" onClick={toggleSidebarStyle}>
          <img src={MenuHideIcon} alt="icon" />
        </div>
      </div>
      <Accordion defaultActiveKey="4">
        {renderLinks(showLabels)} {/* Render links dynamically using the renderLinks function */}
      </Accordion>
    </>
  );

  const navMini = (
    <>
      {/* <div className="menu-icon-otr" onClick={toggleSidebarStyle}>
        <img src={MenuHideIcon} alt="icon" />
      </div> */}
      <div className="logo-menu-otr">
        <div className="logo-otr">
          <p className="showsidebar-header">
            <MiniLogo />
          </p>
        </div>
      </div>
      <Accordion defaultActiveKey="4">
        {renderLinks(showLabels)} {/* Render links dynamically using the renderLinks function */}
      </Accordion>
    </>
  );

  const navMobile = (
    <>
      <div className="show-sidebar-icon d-flex" onClick={handleShow}>
        <img className="logo-space" src={MenuShowIcon} alt="icon" />
        <p className="showsidebar-header pt-3 ps-2">
          VN
        </p>
      </div>
      <Offcanvas show={show} onHide={handleClose} className="sidebar-offcanvas">
        <Offcanvas.Header closeButton>
          <span></span>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={`App sidebar-main`}>
            <Accordion defaultActiveKey="4">{renderLinks(true)}</Accordion>
            <div className="bottom-left p-3">{<LatestRelease minified={false} withToolTip={false} />}</div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );

  return (
    <>
      {navMobile}
      {!showLabels && (
        <div className="menu-icon-otr-1" onClick={toggleSidebarStyle}>
          <img src={ExpandMenuIcon} alt="icon" width={24} />
        </div>
      )}
      <div className={classNames('App sidebar-main hide-side-nav', {'sidebar-mini': !showLabels})}>
        {showLabels ? navMain : navMini}
        <div className="bottom-left p-2">{<LatestRelease minified={!showLabels} />}</div>
      </div>
    </>
  );
};

export default Sidebar;
